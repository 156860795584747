<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-21 21:25:57
 * @LastEditTime: 2020-04-27 15:01:17
 -->
<template>
	<div>
		<div class="home_title">离职人数统计 <span class="home_title_detail" @click="_toDetail">查看明细>></span></div>
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					type="year"
					:clearable="false"
					v-model="searchForm.year"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
				></el-date-picker>
			</el-form-item>
			<!-- <el-button type="primary" size="small">查询</el-button> -->
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting">设置</el-button>
		</el-form>
		<MonthAnalysisCon :year="searchForm.year"></MonthAnalysisCon>
		<QuitReasonAnalysisCon :year="searchForm.year" v-if="forceListIds.indexOf(2) > 0"></QuitReasonAnalysisCon>
		<NoData v-if="forceListIds.indexOf(2) == 0"></NoData>
		<AgeAnalysisCon :year="searchForm.year" v-if="forceListIds.indexOf(3) > 0"></AgeAnalysisCon>
		<NoData v-if="forceListIds.indexOf(3) == 0"></NoData>
		<EducationAnalysisCon :year="searchForm.year" v-if="forceListIds.indexOf(4) > 0"></EducationAnalysisCon>
		<NoData v-if="forceListIds.indexOf(4) == 0"></NoData>
		<ClassificationAnalysisCon
			:year="searchForm.year"
			v-if="orgClassConfigListIds.length > 0"
			:classIdsNew="orgClassConfigListIds"
		></ClassificationAnalysisCon>
		<NoData v-if="orgClassConfigListIds.length == 0"></NoData>
		<LevelAnalysisCon :year="searchForm.year" v-if="levelListIds.length > 0" :levelIdsNew="levelListIds"></LevelAnalysisCon>
		<NoData v-if="levelListIds.length == 0"></NoData>
		<OrgAnalysisCon :year="searchForm.year" v-if="orgInfoListIds.length > 0" :orgInfoIdsNew="orgInfoListIds"></OrgAnalysisCon>
		<NoData v-if="orgInfoListIds.length == 0"></NoData>
		<JobAnalysisCon :year="searchForm.year" v-if="orgJobConfigListIds.length > 0" :orgJobIdsNew="orgJobConfigListIds"></JobAnalysisCon>
		<NoData v-if="orgJobConfigListIds.length == 0"></NoData>
		<DrawerQuitCon
			:listNameArr="['forceListIds', 'orgClassConfigListIds', 'levelListIds', 'orgInfoListIds', 'orgJobConfigListIds']"
			:forceListNew="forceNew"
			:visible.sync="drawer"
			@updateList="_updateList"
			titleNew="离职纬度设置"
		></DrawerQuitCon>
	</div>
</template>
<script>
import DrawerQuitCon from '../components/drawerQuitCon';
import MonthAnalysisCon from './analysis/monthAnalysis';
import QuitReasonAnalysisCon from './analysis/quitReasonAnalysis';
import AgeAnalysisCon from './analysis/ageAnalysis';
import EducationAnalysisCon from './analysis/educationAnalysis';
import ClassificationAnalysisCon from './analysis/classificationAnalysis';
import LevelAnalysisCon from './analysis/levelAnalysis';
import OrgAnalysisCon from './analysis/orgAnalysis';
import JobAnalysisCon from './analysis/jobAnalysis';
import NoData from '../../achievementsmanagement/components/NoneCom';
export default {
	components: {
		DrawerQuitCon,
		QuitReasonAnalysisCon,
		MonthAnalysisCon,
		AgeAnalysisCon,
		EducationAnalysisCon,
		ClassificationAnalysisCon,
		LevelAnalysisCon,
		OrgAnalysisCon,
		JobAnalysisCon,
		NoData
	},
	data() {
		return {
			drawer: false,
			searchForm: {
				year: ''
			},
			forceListIds: [],
			orgClassConfigListIds: [],
			orgJobConfigListIds: [],
			orgInfoListIds: [],
			levelListIds: [],
			forceNew: [
				{ className: '月份', id: 1 },
				{ className: '离职原因', id: 2 },
				{ className: '年龄', id: 3 },
				{ className: '学历', id: 4 }
			]
		};
	},
	methods: {
		_updateList(obj) {
			this[obj.listName] = obj.listDetail;
		},
		_toDetail() {
			this.$router.push({ name: 'OverViewAnalysis', query: { year: this.searchForm.year } });
		}
	},
	mounted() {
		this.searchForm.year = String(new Date().getFullYear());
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.home_title {
	font-size: 16px;
	// padding-bottom: 30px;
	font-weight: bold;
	color: #2b3642;
	// border-bottom: 1px solid #EAEDF1;
	margin-bottom: 20px;
	&:before {
		display: inline-block;
		width: 8px;
		height: 20px;
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: text-bottom;
		margin-right: 8px;
	}
	.home_title_detail {
		font-weight: normal;
		font-size: 14px;
		color: #2b3642;
		margin-left: 30px;
		cursor: pointer;
	}
}
</style>
