<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-21 21:24:18
 * @LastEditTime: 2020-04-25 09:47:16
 -->
<template>
    <div class="app-container">
        <yearCon v-if="show" :orgPermissionList="orgPermissionList"></yearCon>
        <monthCon v-if="show" :orgPermissionList="orgPermissionList"></monthCon>
        <analysisCon v-if="show1" :orgPermissionList="orgPermissionList"></analysisCon>
    </div>
</template>
<script>
import yearCon from './year';
import monthCon from './month';
import analysisCon from './analysis';
import * as orgApi from '@/api/workbench';
export default {
    data() {
        return {
            show: false,
            show1: false,
            orgPermissionList: []
        };
    },
    created() {
        orgApi.getOrgPermissionList({ request: { staffId: '' } }).then((res) => {
            if (res._responseStatusCode == 0) {
                delete res._responseStatusCode;
                this.orgPermissionList = res;
            }
        }).catch((error) => {
            this.orgPermissionList = [];
            console.error('获取部门权限失败', error);
        });
    },
    mounted() {
        this.show = this.permissionControl('/v1/overview/year/cost/statistics');
        this.show1 = this.permissionControl('/v1/overview/quit/Analysis/summary');
    },
    components: { yearCon, monthCon, analysisCon }
};
</script>
<style lang="scss" scoped>
</style>
