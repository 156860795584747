<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-24 13:03:55
 * @LastEditTime: 2020-05-08 15:39:36
 -->
<template>
    <div>
        <div class="itemTitle">{{this.year}}年年龄分析({{totalNumber}}人)</div>
        <el-row class="statisticsRow">
            <el-col :span="4" class="statisticsRow_col">
                <div class="statisticsRow_col_box">
                    <p class="statisticsRow_col_box_top"><span>{{totalNumber}}</span>人</p>
                    <p class="statisticsRow_col_box_bottom">总计</p>
                </div>
            </el-col>
            <el-col :span="4" class="statisticsRow_col" v-for="(item,index) in listTag" :key="index">
                <div class="statisticsRow_col_box">
                    <p class="statisticsRow_col_box_top"><span>{{_returnNumber(item.id)}}</span>人</p>
                    <p class="statisticsRow_col_box_bottom">{{item.name}}</p>
                </div>
            </el-col>
        </el-row>
        <div id="quitAgeGraph"></div>
    </div>
</template>
<script>
import { ageAnalysis } from '@/api/overview';
export default {
    props: ['year'],
    data() {
        return {
            searchForm: {
                startMonth: '',
                endMonth: '',
                type: 0
            },
            list: [],
            totalNumber: 0,
            listTag: [
                {name: '20岁以下', id: 'underTwenty'},
                {name: '20-30岁', id: 'twentyToThirtyYearsOld'},
                {name: '30-40岁', id: 'thirtyToFortyYearsOld'},
                {name: '40-50岁', id: 'fortyToFiftyYearsOld'},
                {name: '50岁以上', id: 'overFifty'}
            ]
        };
    },
    methods: {
        _getDetail() {
            ageAnalysis(this.searchForm).then((res) => {
                this.list = res;
                this.totalNumber = res[0].totalNumber || 0;
                this._getTopGraph();
            });
        },
        _returnNumber(name) {
            for (const key in this.list[0]) {
                if (key == name) {
                    return this.list[0][key];
                }
            }
        },
        _getTopGraph() {
            const quitAgeGraph = this.$echarts.init(document.getElementById('quitAgeGraph'));

            if (this.list.length == 0) {
                quitAgeGraph.clear();
                return false;
            }
            const _series = [];

            const _xList = ['20岁以下', '20-30岁', '30-40岁', '40-50岁', '50岁以上'];

            _series.push({
                name: this.list[0].name,
                type: 'bar',
                color: '#498DF0',
                data: [this.list[0].underTwenty, this.list[0].twentyToThirtyYearsOld, this.list[0].thirtyToFortyYearsOld, this.list[0].fortyToFiftyYearsOld, this.list[0].overFifty]
            });
            _series.push({
                name: this.list[1].name,
                type: 'line',
                yAxisIndex: 1,
                color: '#FFC457',
                data: [
                    Number(this.list[1].underTwenty.replace(/%/, '')),
                    Number(this.list[1].twentyToThirtyYearsOld.replace(/%/, '')),
                    Number(this.list[1].thirtyToFortyYearsOld.replace(/%/, '')),
                    Number(this.list[1].fortyToFiftyYearsOld.replace(/%/, '')),
                    Number(this.list[1].fortyToFiftyYearsOld.replace(/%/, '')),
                    Number(this.list[1].overFifty.replace(/%/, ''))
                ],
                label: {
                    normal: {
                        formatter(params) { // 标签内容
                            return `${params.value}%`;
                        }
                    }
                },
                itemStyle: { normal: {label: {show: true}}}
            });
            quitAgeGraph.setOption(
                {
                    title: {
                        text: '年龄分析图',
                        textStyle: {
                            fontSize: 16,
                            color: '#2B3642'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        formatter(params) {
                            let str = `${params[0].name}<br>`;

                            for (const item of params) {
                                str += `${item.seriesName} : ${item.seriesName == '百分比' ? `${item.value}%<br>` : `${item.value}<br>`}`;
                            }
                            return str;
                        }
                    },
                    legend: {
                        data: [this.list[0].name, this.list[1].name],
                        top: 30,
                        right: 70
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {show: true}
                        }
                    },
                    color: ['#498DF0', '#60C6BB', '#7DDCFF', '#7ED321', '#FBD233', '#FF7E00', '#FF7371', '#A142FF', '#A77CFF'],
                    grid: {
                        top: '25%',
                        left: '3%',
                        right: '5%',
                        bottom: '0%',
                        containLabel: true,
                        show: false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: true,
                            splitLine: {
                                show: false
                            },
                            data: _xList
                        }
                    ],
                    yAxis: [

                        {
                            name: '总人数\n(人)',
                            type: 'value',
                            minInterval: 1,
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        },
                        {
                            name: '人数\n(人)',
                            type: 'value',
                            minInterval: 1,
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        }
                    ],
                    series: _series
                }
            );
        }
    },
    mounted() {
        this.searchForm.startMonth = `${this.year}-01`;
        this.searchForm.endMonth = `${this.year}-12`;
    },
    watch: {
        year: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.searchForm.startMonth = `${newVal}-01`;
                this.searchForm.endMonth = `${newVal}-12`;
                this._getDetail();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.itemTitle{
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    font-weight: bold;
    color: #2B3642;
    margin: 20px 0;
}
.statisticsRow{
    background: rgba(73,141,240,0.09);
    border-radius: 4px;
    margin-bottom: 20px;
    .statisticsRow_col{
        .statisticsRow_col_box{
            padding: 25px 20px;
            .statisticsRow_col_box_top{
                font-size: 14px;
                color: #1E1E1E;
                margin-bottom: 8px;
                font-weight: bold;
                span{
                    font-size: 24px;
                    color: #498DF0;
                }
            }
            .statisticsRow_col_box_bottom{
                font-size: 14px;
                color: #484848;
            }
        }
    }
}
#quitAgeGraph{
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
}
</style>
