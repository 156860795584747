<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-21 21:25:38
 * @LastEditTime: 2020-05-08 15:52:32
 -->
<template>
	<div>
		<div class="home_title">月度成本薪酬福利统计 <span class="home_title_detail" @click="_toDetail">查看明细>></span></div>
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					type="year"
					:clearable="false"
					v-model="searchForm.year"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
				></el-date-picker>
			</el-form-item>
			<!-- <el-button type="primary" size="small" @click="_getDetail">查询</el-button> -->
			<el-button type="primary" size="small" icon="el-icon-setting" @click="drawer = true">设置</el-button>
		</el-form>
		<el-row class="statisticsRow" ref="statisticsRow" v-if="showRow">
			<template v-for="(item, index) in costStatisticsSummary || []">
				<el-col :span="4" :key="index" class="statisticsRow_col">
					<div class="statisticsRow_col_box">
						<p class="statisticsRow_col_box_top">
							<span>{{ item.totalMoney }}</span
							>（元）
						</p>
						<p class="statisticsRow_col_box_bottom">{{ item.name }}</p>
					</div>
				</el-col>
			</template>
			<!-- <el-col :span="4" class="statisticsRow_col" v-if="costStatisticsSummary && costStatisticsSummary.length > 12">
                <div class="statisticsRow_col_box">
                    <p class="statisticsRow_col_box_top"><span>{{_returnOtherNum()}}</span>（万元）</p>
                    <p class="statisticsRow_col_box_bottom">其他</p>
                </div>
            </el-col> -->
		</el-row>
		<div id="middleGraph"></div>
		<DrawerCon
			:visible.sync="drawer"
			:listNameArr="['wagesConfigIds', 'companyConfigIds']"
			:mixAble="1"
			:limit="true"
			@updateList="_updateList"
		></DrawerCon>
	</div>
</template>
<script>
import DrawerCon from '../components/drawerCon';
import { costStatisticsMonth } from '@/api/overview';
export default {
	components: { DrawerCon },
	data() {
		return {
			drawer: false,
			searchForm: {
				year: '',
				wagesConfigIds: [],
				companyConfigIds: []
			},
			showRow: false,
			detail: {},
			costStatisticsSummary: []
		};
	},
	methods: {
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		},
		_getDetail() {
			for (const key in this.searchForm) {
				if (this.searchForm[key] === '' || this.searchForm[key].length == 0) {
					return false;
				}
			}
			this.showRow = false;
			costStatisticsMonth(this.searchForm).then((res) => {
				this.detail = res;
				if (res.costStatisticsSummary != null) {
					const _costStatisticsSummary = [...res.costStatisticsSummary];

					if (this._returnOtherNum() > 0) {
						_costStatisticsSummary.push({ name: '其他', totalMoney: this._returnOtherNum() });
					}
					this.$nextTick(() => {
						this.costStatisticsSummary = _costStatisticsSummary;
						this.showRow = true;
					});
				}
				this._getTopGraph();
				this.$forceUpdate();
			});
		},
		_returnOtherNum() {
			let _num = 0;

			this.detail.costStatisticsSummary.forEach((item, index) => {
				index != 0 ? (_num += Number(item.totalMoney)) : '';
			});
			return Number(this.detail.costStatisticsSummary[0].totalMoney) - _num;
		},
		_toDetail() {
			this.$router.push({ name: 'OverViewSalary', query: { type: '2', year: this.searchForm.year } });
		},
		_getTopGraph() {
			const middleGraph = this.$echarts.init(document.getElementById('middleGraph'));

			if (this.detail.monthCostStatisticsList == null) {
				middleGraph.clear();
				return false;
			}
			const _xList = [];

			const _Legend = [];

			const _series = {};

			const _seriesList = [];

			if (this.detail.monthCostStatisticsList) {
				this.detail.monthCostStatisticsList[0].statisticsList.forEach((item) => {
					if (item.name != '合计') {
						_Legend.push({
							name: item.uniqueValue.includes('-0')
								? item.uniqueValue.replace('-0', '(个人)')
								: item.uniqueValue.replace('-1', '(公司)'),
							icon: 'rect'
						});
					} else {
						_Legend.push(item.name);
					}
					_series[item.uniqueValue] = {
						name: item.uniqueValue.includes('-0')
							? item.uniqueValue.replace('-0', '(个人)')
							: item.uniqueValue.includes('-1')
							? item.uniqueValue.replace('-1', '(公司)')
							: item.name,
						type: 'line',
						stack: '总量',
						areaStyle: {},
						data: [],
						symbol: item.name == '合计' ? 'emptyCircle' : 'none',
						label: { show: item.name == '合计' }
					};
				});
				this.detail.monthCostStatisticsList.forEach((item) => {
					_xList.push(item.month);
					item.statisticsList.forEach((child) => {
						_series[child.uniqueValue].data.push(child.totalMoney);
					});
				});
				for (const key in _series) {
					_seriesList.push(_series[key]);
				}
			}
			// console.log(_seriesList)
			middleGraph.setOption(
				{
					title: {
						text: `${String(this.searchForm.year)}年各月人力成本薪酬福利趋势图`
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					color: ['#2EE0FF', '#498DF0', '#60C6BB', '#7DDCFF', '#7ED321', '#FBD233', '#FF7E00', '#FF7371', '#A142FF', '#A77CFF'],
					legend: {
						type: 'scroll',
						data: _Legend,
						top: 35,
						right: '3%'
					},
					toolbox: {
						feature: {
							saveAsImage: { show: true }
						}
					},
					grid: {
						top: '22%',
						left: '3%',
						right: '4%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [
						{
							type: 'category',
							boundaryGap: false,
							data: _xList
						}
					],
					yAxis: [
						{
							type: 'value'
						}
					],
					series: _seriesList
				},
				true
			);
		}
	},
	mounted() {
		this.searchForm.year = String(new Date().getFullYear());
	},
	watch: {
		'searchForm.year': {
			deep: true,
			handler() {
				this._getDetail();
			}
		},
		'searchForm.wagesConfigIds': {
			deep: true,
			handler() {
				this._getDetail();
			}
		},
		'searchForm.companyConfigIds': {
			deep: true,
			handler() {
				this._getDetail();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.home_title {
	font-size: 16px;
	// padding-bottom: 30px;
	font-weight: bold;
	color: #2b3642;
	// border-bottom: 1px solid #EAEDF1;
	margin-bottom: 20px;
	&:before {
		display: inline-block;
		width: 8px;
		height: 20px;
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: text-bottom;
		margin-right: 8px;
	}
	.home_title_detail {
		font-weight: normal;
		font-size: 14px;
		color: #2b3642;
		margin-left: 30px;
		cursor: pointer;
	}
}
.statisticsRow {
	background: rgba(73, 141, 240, 0.09);
	border-radius: 4px;
	margin-bottom: 20px;
	.statisticsRow_col {
		.statisticsRow_col_box {
			padding: 25px 20px;
			.statisticsRow_col_box_top {
				font-size: 14px;
				color: #1e1e1e;
				margin-bottom: 8px;
				font-weight: bold;
				span {
					font-size: 24px;
					color: #498df0;
				}
			}
			.statisticsRow_col_box_bottom {
				font-size: 14px;
				color: #484848;
			}
		}
	}
}
#middleGraph {
	height: 400px;
	width: 100%;
	margin-bottom: 40px;
}
</style>
