<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-24 17:31:20
 * @LastEditTime: 2020-05-08 15:43:29
 -->
<template>
    <div>
        <div class="itemTitle">{{this.year}}年部门分析({{totalNumber}}人)</div>
        <el-row class="statisticsRow">
            <el-col :span="4" class="statisticsRow_col">
                <div class="statisticsRow_col_box">
                    <p class="statisticsRow_col_box_top"><span>{{totalNumber}}</span>人</p>
                    <p class="statisticsRow_col_box_bottom">总计</p>
                </div>
            </el-col>
            <el-col :span="4" class="statisticsRow_col" v-for="(item,index) in list" :key="new Date()+index" v-show="list.length <= 11 || list.length > 11 && index < 9">
                <div class="statisticsRow_col_box">
                    <p class="statisticsRow_col_box_top"><span>{{item.number}}</span>人</p>
                    <p class="statisticsRow_col_box_bottom">{{item.name}}</p>
                </div>
            </el-col>
            <el-col :span="4" class="statisticsRow_col" v-if="list.length > 11">
                <div class="statisticsRow_col_box">
                    <p class="statisticsRow_col_box_top"><span>{{_returnOtherNum()}}</span>人</p>
                    <p class="statisticsRow_col_box_bottom">其他</p>
                </div>
            </el-col>
        </el-row>
        <div id="orgGraph"></div>
        <NoData v-show="list.length==0"></NoData>
    </div>
</template>
<script>
import { departmentAnalysis } from '@/api/overview';
import NoData from '../../../achievementsmanagement/components/NoneCom';
export default {
    props: ['year', 'orgInfoIdsNew'],
    components: {NoData},
    data() {
        return {
            searchForm: {
                startMonth: '',
                endMonth: '',
                orgIds: this.orgInfoIdsNew,
                type: 0,
                pageSize: 10000,
                pageNo: 1
            },
            list: [],
            totalNumber: 0
        };
    },
    methods: {
        _getDetail() {
            departmentAnalysis(this.searchForm).then((res) => {
                this.list = res.list;
                this.totalNumber = res.list[0] ? res.list[0].totalNumber : 0;
                this._getTopGraph();
            });
        },
        _returnOtherNum() {
            let _num = 0;

            this.list.forEach((item, index) => {
                index < 10 ? _num += Number(item.number) : '';
            });
            return Number(this.totalNumber) - _num;
        },
        _getTopGraph() {
            const orgGraph = this.$echarts.init(document.getElementById('orgGraph'));

            if (this.list.length == 0) {
                orgGraph.clear();
                document.querySelector('#orgGraph').style.display = 'none';
                return false;
            }
            const _xlist = [];

            const _numbers = [];

            const _proportion = [];

            this.list.forEach((item) => {
                _xlist.push(item.name);
                _numbers.push(item.number);
                _proportion.push(Number(item.proportion.replace(/%/, '')));
            });
            orgGraph.setOption(
                {
                    title: {
                        text: '部门分析图',
                        textStyle: {
                            fontSize: 16,
                            color: '#2B3642'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        formatter(params) {
                            let str = `${params[0].name}<br>`;

                            for (const item of params) {
                                str += `${item.seriesName} : ${item.seriesName == '百分比' ? `${item.value}%<br>` : `${item.value}<br>`}`;
                            }
                            return str;
                        }
                    },
                    legend: {
                        data: ['人数', '百分比'],
                        top: 30,
                        right: 70
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {show: true}
                        }
                    },
                    grid: {
                        top: '25%',
                        left: '3%',
                        right: '5%',
                        bottom: '0%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        splitLine: {
                            show: false
                        },
                        data: _xlist
                    },
                    yAxis: [
                        {
                            name: '人数\n(人)',
                            type: 'value',
                            minInterval: 1,
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        },
                        {
                            name: '百分比\n(%)',
                            type: 'value',
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        }
                    ],
                    series: [
                        {
                            name: '人数',
                            type: 'bar',
                            stack: '总量',
                            color: '#498DF0',
                            data: _numbers
                        },
                        {
                            name: '百分比',
                            type: 'line',
                            yAxisIndex: 1,
                            color: '#FFC457',
                            data: _proportion,
                            label: {
                                normal: {
                                    formatter(params) { // 标签内容
                                        return `${params.value}%`;
                                    }
                                }
                            },
                            itemStyle: { normal: {label: {show: true}}}
                        }
                    ]
                }
            );
        }
    },
    mounted() {
        this.searchForm.startMonth = `${this.year}-01`;
        this.searchForm.endMonth = `${this.year}-12`;
    },
    watch: {
        year: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.searchForm.startMonth = `${newVal}-01`;
                this.searchForm.endMonth = `${newVal}-12`;
                this._getDetail();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.itemTitle{
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #2B3642;
    font-weight: bold;
    margin: 25px 0 20px;
}
.statisticsRow{
    background: rgba(73,141,240,0.09);
    border-radius: 4px;
    margin-bottom: 20px;
    .statisticsRow_col{
        .statisticsRow_col_box{
            padding: 25px 20px;
            .statisticsRow_col_box_top{
                font-size: 14px;
                color: #1E1E1E;
                margin-bottom: 8px;
                font-weight: bold;
                span{
                    font-size: 24px;
                    color: #498DF0;
                }
            }
            .statisticsRow_col_box_bottom{
                font-size: 14px;
                color: #484848;
            }
        }
    }
}
#orgGraph{
    height: 400px;
    width: 100%;
}
</style>
