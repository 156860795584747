<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-21 21:25:28
 * @LastEditTime: 2020-05-06 13:41:57
 -->
<template>
	<div>
		<div class="home_title">年度成本薪酬福利统计 <span class="home_title_detail" @click="_toDetail">查看明细>></span></div>
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					type="year"
					:clearable="false"
					v-model="searchForm.contrastYear"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					type="year"
					:clearable="false"
					v-model="searchForm.targetYear"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px; margin-right: 5px"
				></el-date-picker>
			</el-form-item>
			<!-- <el-button type="primary" size="small" @click="_getDetail">查询</el-button> -->
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting">设置</el-button>
		</el-form>
		<el-row class="statisticsRow">
			<el-col :span="8" class="statisticsRow_col">
				<div class="statisticsRow_col_box">
					<p class="statisticsRow_col_box_top">
						<span>{{ detail.contrastTotalMoney }}</span
						>（元）
					</p>
					<p class="statisticsRow_col_box_bottom">{{ searchForm.contrastYear }}总费用</p>
				</div>
			</el-col>
			<el-col :span="8" class="statisticsRow_col">
				<div class="statisticsRow_col_box">
					<p class="statisticsRow_col_box_top">
						<span>{{ detail.targetTotalMoney }}</span
						>（元）
					</p>
					<p class="statisticsRow_col_box_bottom">{{ searchForm.targetYear }}总费用</p>
				</div>
			</el-col>
			<el-col :span="8" class="statisticsRow_col">
				<div class="statisticsRow_col_box">
					<p class="statisticsRow_col_box_top">
						<span>{{ detail.totalGrowthRate }}</span
						>（%）
					</p>
					<p class="statisticsRow_col_box_bottom">增长率</p>
				</div>
			</el-col>
		</el-row>
		<div>
			<div id="topGraph"></div>
			<el-row :gutter="20">
				<el-col :span="12">
					<div id="leftRound"></div>
				</el-col>
				<el-col :span="12">
					<div id="rightRound"></div>
				</el-col>
			</el-row>
		</div>
		<DrawerCon
			:visible.sync="drawer"
			:listNameArr="['wagesConfigIds', 'companyConfigIds']"
			:mixAble="1"
			:limit="true"
			@updateList="_updateList"
			:annualBonusNew.sync="searchForm.annualBonus"
		></DrawerCon>
	</div>
</template>
<script>
import DrawerCon from '../components/drawerCon';
import { costStatistics } from '@/api/overview';
export default {
	props: ['orgPermissionList'],
	components: { DrawerCon },
	data() {
		return {
			searchForm: {
				contrastYear: '',
				targetYear: '',
				annualBonus: true,
				wagesConfigIds: [],
				companyConfigIds: []
			},
			detail: {},
			drawer: false
		};
	},
	methods: {
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		},
		_getDetail() {
			for (const key in this.searchForm) {
				if (this.searchForm[key] === '' || this.searchForm[key].length == 0) {
					return false;
				}
			}
			console.log('当前用户拥有的权限列表：', this.$props.orgPermissionList);
			this.searchForm.orgPermissionList = this.$props.orgPermissionList;
			costStatistics(this.searchForm).then((res) => {
				this.detail = res;
				this._getTopGraph();
			});
		},
		_toDetail() {
			this.$router.push({
				name: 'OverViewSalary',
				query: { type: '1', contrastYear: this.searchForm.contrastYear, targetYear: this.searchForm.targetYear }
			});
		},
		_getTopGraph() {
			const topGraph = this.$echarts.init(document.getElementById('topGraph'));

			const leftRound = this.$echarts.init(document.getElementById('leftRound'));

			const rightRound = this.$echarts.init(document.getElementById('rightRound'));
			const _contrastYear = String(this.searchForm.contrastYear);
			const _targetYear = String(this.searchForm.targetYear);

			const _xList = [];

			const _contrastYearData = [];

			const _contrastYearDataValue = [];

			const _targetYearData = [];

			const _targetYearDataValue = [];

			this.detail.contrastYearCostStatisticsList.forEach((item) => {
				_xList.push(item.name);
				_contrastYearData.push(item.totalMoney);
				_contrastYearDataValue.push({ name: item.name, value: item.totalMoney });
			});
			this.detail.targetYearCostStatisticsList.forEach((item) => {
				_targetYearData.push(item.totalMoney);
				_targetYearDataValue.push({ name: item.name, value: item.totalMoney });
			});
			topGraph.setOption(
				{
					title: {
						text: '人力成本薪酬福利总体趋势图',
						textStyle: {
							fontSize: 16,
							color: '#2B3642'
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						},
						formatter(params) {
							let str = `${params[0].name}<br>`;

							for (const item of params) {
								str += `${item.seriesName} : ${item.seriesName == '增长率' ? `${item.value}%<br>` : `${item.value}<br>`}`;
							}
							return str;
						}
					},
					legend: {
						data: [_contrastYear, _targetYear, '增长率'],
						top: 28,
						right: 40
					},
					grid: {
						top: '25%',
						left: '3%',
						right: '3%',
						bottom: '0%',
						containLabel: true,
						show: false
					},
					toolbox: {
						feature: {
							saveAsImage: { show: true }
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: true,
						splitLine: {
							show: false
						},
						data: _xList
					},
					yAxis: [
						{
							name: '成本\n(万元)',
							type: 'value',
							splitLine: {
								show: false
							},
							axisLabel: {
								formatter: '{value}'
							}
						},
						{
							name: '增长率\n(%)',
							type: 'value',
							splitLine: {
								show: false
							},
							axisLabel: {
								formatter: '{value}'
							}
						}
					],
					series: [
						{
							name: _contrastYear,
							type: 'bar',
							color: '#498DF0',
							data: _contrastYearData
						},
						{
							name: _targetYear,
							type: 'bar',
							color: '#FF7371',
							data: _targetYearData
						},
						{
							name: '增长率',
							type: 'line',
							yAxisIndex: 1,
							color: '#FFC457',
							data: this.detail.growthRateList,
							label: {
								normal: {
									formatter(params) {
										// 标签内容
										return `${params.value}%`;
									}
								}
							},
							itemStyle: { normal: { label: { show: true } } }
						}
					]
				},
				true
			);
			leftRound.setOption(
				{
					title: {
						text: `${_contrastYear}年人力成本薪酬福利占比图`
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} ({d}%)'
					},
					legend: {
						type: 'scroll',
						orient: 'vertical',
						right: 10,
						top: 90,
						bottom: 20,
						data: _xList
					},
					color: ['#498DF0', '#60C6BB', '#7DDCFF', '#7ED321', '#FFE800', '#FFAC00', '#FF7371', '#A142FF', '#A77CFF'],
					series: [
						{
							name: '种类',
							type: 'pie',
							radius: '55%',
							center: ['40%', '50%'],
							data: _contrastYearDataValue,
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				},
				true
			);
			rightRound.setOption(
				{
					title: {
						text: `${_targetYear}年人力成本薪酬福利占比图`
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} ({d}%)'
					},
					legend: {
						type: 'scroll',
						orient: 'vertical',
						right: 10,
						top: 90,
						bottom: 20,
						data: _xList
					},
					color: ['#498DF0', '#60C6BB', '#7DDCFF', '#7ED321', '#FFE800', '#FFAC00', '#FF7371', '#A142FF', '#A77CFF'],
					series: [
						{
							name: '种类',
							type: 'pie',
							radius: '55%',
							center: ['40%', '50%'],
							data: _targetYearDataValue,
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				},
				true
			);
		}
	},
	mounted() {
		const nowyear = new Date().getFullYear();

		this.searchForm.contrastYear = String(nowyear - 1);
		this.searchForm.targetYear = String(nowyear);
	},
	watch: {
		'searchForm.contrastYear': {
			deep: true,
			handler() {
				this._getDetail();
			}
		},
		'searchForm.targetYear': {
			deep: true,
			handler() {
				this._getDetail();
			}
		},
		'searchForm.annualBonus': {
			deep: true,
			handler() {
				this._getDetail();
			}
		},
		'searchForm.wagesConfigIds': {
			deep: true,
			handler() {
				this._getDetail();
			}
		},
		'searchForm.companyConfigIds': {
			deep: true,
			handler() {
				this._getDetail();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.home_title {
	font-size: 16px;
	// padding-bottom: 20px;
	font-weight: bold;
	color: #2b3642;
	// border-bottom: 1px solid #EAEDF1;
	margin-bottom: 20px;
	&:before {
		display: inline-block;
		width: 8px;
		height: 20px;
		transform: translateY(1px);
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: text-bottom;
		margin-right: 8px;
	}
	.home_title_detail {
		font-weight: normal;
		font-size: 14px;
		color: #2b3642;
		margin-left: 30px;
		cursor: pointer;
		display: inline-block;
		transform: translateY(-1px);
	}
}
.statisticsRow {
	background: rgba(73, 141, 240, 0.09);
	border-radius: 4px;
	margin-bottom: 20px;
	.statisticsRow_col {
		.statisticsRow_col_box {
			padding: 25px 10px;
			text-align: center;
			.statisticsRow_col_box_top {
				font-weight: bold;
				font-size: 14px;
				color: #1e1e1e;
				margin-bottom: 8px;
				span {
					font-size: 24px;
					color: #498df0;
				}
			}
			.statisticsRow_col_box_bottom {
				font-size: 14px;
				color: #484848;
			}
		}
	}
}
#topGraph,
#leftRound,
#rightRound {
	height: 400px;
	width: 100%;
	margin-bottom: 20px;
}
</style>
