<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-24 12:04:30
 * @LastEditTime: 2020-05-08 15:38:50
 -->
<template>
    <section>
        <NoData  v-if="detail.length == 0"></NoData>
        <div id="bottomGraph" v-else></div>
    </section>
</template>
<script>
import NoData from '../../../achievementsmanagement/components/NoneCom';
import { analysisSummary } from '@/api/overview';
export default {
    components: {NoData},
    props: ['year'],
    data() {
        return {
            searchForm: {
                year: ''
            },
            detail: {}
        };
    },
    methods: {
        _getDetail() {
            analysisSummary(this.searchForm).then((res) => {
                delete res._responseStatusCode;
                this.detail = res;
                this._getTopGraph();
            });
        },
        _getTopGraph() {
            const bottomGraph = this.$echarts.init(document.getElementById('bottomGraph'));

            if (this.detail.length == 0) {
                bottomGraph.clear();
                return false;
            }
            // let _january = 0;
            // let _february = 0;
            // let _march = 0;
            // let _april = 0;
            // let _may = 0;
            // let _june = 0;
            // let _july = 0;
            // let _august = 0;
            // let _september = 0;
            // let _october = 0;
            // let _november = 0;
            // let _december = 0;
            let _reasonProportion = [];
            const _reasonChild = this.detail[this.detail.length - 1];
            const _quitList = this.detail.filter((item) => item.reason == '离职总人数');
            // this.detail.forEach((item,index) => {
            //     if(index != this.detail.length-1){
            //         _january+= Number(item.january);
            //         _february+= Number(item.february);
            //         _march+= Number(item.march);
            //         _april+= Number(item.april);
            //         _may+= Number(item.may);
            //         _june+= Number(item.june);
            //         _july+= Number(item.july);
            //         _august+= Number(item.august);
            //         _september+= Number(item.september);
            //         _october+= Number(item.october);
            //         _november+= Number(item.november);
            //         _december+= Number(item.december);
            //     }
            // })

            _reasonProportion = [_reasonChild.january, _reasonChild.february, _reasonChild.march, _reasonChild.april, _reasonChild.may, _reasonChild.june, _reasonChild.july, _reasonChild.august, _reasonChild.september, _reasonChild.september, _reasonChild.november, _reasonChild.december];
            const _barList = [_quitList[0].january, _quitList[0].february, _quitList[0].march, _quitList[0].april, _quitList[0].may, _quitList[0].june, _quitList[0].july, _quitList[0].august, _quitList[0].september, _quitList[0].october, _quitList[0].november, _quitList[0].december];

            let _total = 0;

            _barList.forEach((item) => {
                _total += Number(item);
            });
            bottomGraph.setOption(
                {
                    title: {
                        text: `${String(this.searchForm.year)}年离职趋势图(${_total}人)`,
                        textStyle: {
                            fontSize: 16,
                            color: '#2B3642'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        formatter(params) {
                            let str = `${params[0].name}<br>`;

                            for (const item of params) {
                                str += `${item.seriesName} : ${item.seriesName == '离职率' ? `${item.value}%<br>` : `${item.value}<br>`}`;
                            }
                            return str;
                        }
                    },
                    legend: {
                        data: [`${this.searchForm.year}离职人数`, '离职率'],
                        top: 30,
                        right: 70
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {show: true}
                        }
                    },
                    grid: {
                        top: '25%',
                        left: '3%',
                        right: '5%',
                        bottom: '0%',
                        containLabel: true,
                        show: false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: true,
                            splitLine: {
                                show: false
                            },
                            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                        }
                    ],
                    yAxis: [
                        {
                            name: '人数\n(人)',
                            type: 'value',
                            minInterval: 1,
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        },
                        {
                            name: '离职率\n(%)',
                            type: 'value',
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        }
                    ],
                    series: [
                        {
                            name: `${this.searchForm.year}离职人数`,
                            type: 'bar',
                            color: '#498DF0',
                            data: _barList
                        },
                        {
                            name: '离职率',
                            type: 'line',
                            yAxisIndex: 1,
                            color: '#FFC457',
                            label: {
                                normal: {
                                    formatter(params) { // 标签内容
                                        return `${params.value}%`;
                                    }
                                }
                            },
                            itemStyle: { normal: {label: {show: true}}},
                            data: _reasonProportion
                        }
                    ]
                }
            );
        }
    },
    watch: {
        year: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.searchForm.year = newVal;
                this._getDetail();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
#bottomGraph{
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
}
</style>
